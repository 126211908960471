import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  companies: null,
}

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompaniesSlice(state, action) {
      state.companies = action.payload
    },
    addCompanySlice(state, action) {
      const comp = action.payload
      state.companies = [comp, ...state.companies]
    },
    removeCompanySlice(state, action) {
      const compArray = action.payload
      if (compArray.id) {
        state.companies = state.companies.filter((company) => company.id !== compArray.id)
      } else {
        const ids = compArray.ids
        state.companies = state.companies.filter((company) => !ids.includes(company.id))
      }
    },
    changeCompanySlice(state, action) {
      const comp = action.payload
      state.companies = state.companies.map((company) => {
        if (company.id === comp.id) {
          return { ...company, ...comp }
        }
        return company
      })
    },
  },
})

export const { setCompaniesSlice, addCompanySlice, removeCompanySlice, changeCompanySlice } = companiesSlice.actions

export default companiesSlice.reducer
